import { css } from 'styled-components';

export const colors = {
  brand: 'rgb(122, 105, 191)',
  mutedText: '#666',
  border: '#e6e6e6',
  grey90: '#e6e6e6',
  photoGradientStart: 'rgba(0, 0, 0, 0)',
  photoGradientEnd: 'rgba(0, 0, 0, 0.4)',
  blueBackground: '#0067CC',
  background: '#f8f8f8',
  grey97: '#f8f8f8',
  grey95: '#f2f2f2',
};

export const fonts = {
  sans:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;',
  brand: '"Quicksand", sans-serif',
};

export const buttons = {
  primary: {
    backgroundColor: colors.brand,
    color: '#fff',
    background: 'linear-gradient(180deg, #9A35EC 0%, #6023E6 100%)',
    borderRadius: '25px',
    border: `1px solid ${colors.brand}`,
  },
  unset: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
};

export const sizes = {
  giant: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
  smallPhone: 414,
  iphone6: 375,
};

export const breakpoints = [
  // '375px',
  '414px',
  '576px',
  '768px',
  '992px',
  '1200px',
];

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16;
  return {
    ...acc,
    [label]: (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)}
      }
    `,
  };
}, {});

export default {
  colors,
  fonts,
  buttons,
  sizes,
  media,
  breakpoints,
};
