import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { produce } from 'immer';
import normalize from 'jsonapi-normalizer';

const initialState = {
  likes: {},
  comments: {},
  pages: {},
  texts: {},
  videos: {},
  audios: {},
  stories: {},
  members: {},
  'story-shares': {},
};

export const actionTypes = {
  RECEIVE_ENTITIES: 'RECEIVE_ENTITIES',
};

export const receiveEntities = data => ({
  type: actionTypes.RECEIVE_ENTITIES,
  data,
});

/* eslint-disable no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case actionTypes.RECEIVE_ENTITIES: {
        // normalize payload
        const { entities } = normalize(action.data);

        Object.keys(entities).forEach(entityType => {
          Object.keys(entities[entityType]).forEach(id => {
            if (!draft[entityType]) {
              draft[entityType] = {};
            }
            draft[entityType][id] = entities[entityType][id];
          });
        });

        // eslint-disable-next-line
        return;
      }
    }
  });

export function initializeStore(state = initialState) {
  return createStore(reducer, state, composeWithDevTools(applyMiddleware()));
}
